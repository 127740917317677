import axios from 'axios';
import * as Sentry from '@sentry/vue';

import request from '../../services/request';
import utils from '@/utils';

export const state = {
  currentOrg: {},
  defaultCampaign: {}
};

export const mutations = {
  SET_CURRENT_ORG(state, newValue) {
    if (!newValue) {
      return;
    }
    Sentry.setTag('org', newValue.name);
    state.currentOrg = newValue;
  },

  SET_ORG_CRM_SETTINGS(state, newValue) {
    state.currentOrg.crmSettings = newValue;
  },

  SET_ORG_HUBSPOT_CONNECTION(state, newValue) {
    state.currentOrg.hubspotConnection = newValue;
  }
};

export const getters = {
  getOrg(state) {
    return state.currentOrg;
  },
  getOrgId(state) {
    return state.currentOrg.id;
  },
  getEntities(state) {
    return state.currentOrg.entities;
  },
  getPrimaryEntity(state) {
    return state.currentOrg.entities.find((entity) => entity.isPrimary);
  },
  getEntity: (state) => (id) => {
    return state.currentOrg.entities.find((entity) => entity.id === id);
  },
  getOrgCurrency(state) {
    return state.currentOrg.currency;
  },
  getEntityCurrencySymbol:
    (_state, getters) =>
    (entityId = null) => {
      const entity = getters.getEntity(entityId) || getters.getPrimaryEntity;
      return utils.getCurrencySymbol(entity.currency);
    },
  subOrgs(state) {
    return state.currentOrg.subOrgs || [];
  }

  // completedTourSteps (state) {
  //   return state.currentOrg.crmSettings.completedTourSteps || {};
  // },
  // isAllTourStepsCompleted (state, getters) {
  //   const steps = ['customization', 'integration', 'installation'];
  //   const completedSteps = Object.keys(getters.completedTourSteps); // If key exists it should always be true
  //   const isAllTourStepsCompleted = steps.every((step) => completedSteps.includes(step));
  //   return isAllTourStepsCompleted;
  // }
};

export const actions = {
  async createOrg(_, form) {
    const { data, error } = await request.post('/sign-up', { form });
    if (error) {
      throw new Error(data);
    } else {
      return data;
    }
  },

  async saveOrg({ commit, state }, { org, orgLogo, deleteLogo = false, orgId = state.currentOrg.id }) {
    if (!orgId) {
      throw new Error('org id is missing');
    }

    const bodyFormData = new FormData();
    bodyFormData.append('org', JSON.stringify(org));
    bodyFormData.append('orgLogo', orgLogo);
    if (deleteLogo) {
      bodyFormData.append('deleteLogo', deleteLogo);
    }

    const { data, error } = await axios({
      method: 'put',
      url: `${import.meta.env.VITE_APP_SERVER_URL}/org/${orgId}`,
      data: bodyFormData,
      withCredentials: true,
      'Content-Type': 'multipart/form-data'
    });

    if (error) {
      throw new Error(data);
    } else {
      const updatedOrg = data.data;
      const isCurrentOrg = orgId === state.currentOrg.id;
      if (isCurrentOrg) {
        // Is not current org (superuser)
        commit('SET_CURRENT_ORG', updatedOrg);
      }
      return updatedOrg;
    }
  },

  async saveOrgSettings({ commit }, { settings }) {
    const { data, error } = await request.post(`/org/settings`, { settings });
    if (error) {
      throw new Error(data);
    }

    commit('SET_CURRENT_ORG', data);
    return data;
  },

  async saveOrgCrmSettings({ commit, state }, { crmSettings, feePercentage, orgId = state.currentOrg.id }) {
    if (!orgId) {
      throw new Error('org id is missing');
    }
    const { data, error } = await request.post(`admin/org/${orgId}/crm-settings`, { crmSettings, feePercentage });
    if (error) {
      throw new Error(data);
    } else {
      const updatedOrg = data;
      commit('SET_CURRENT_ORG', updatedOrg);
      return updatedOrg;
    }
  },

  async fetchOrg({ state }, orgId = state.currentOrg.id) {
    const { data, error } = await request.get(`/org/${orgId}`);
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async saveEntity({ commit }, { entityId, entity, logo, deleteLogo = false }) {
    const bodyFormData = new FormData();
    bodyFormData.append('entity', JSON.stringify(entity));
    bodyFormData.append('logo', logo);
    if (deleteLogo) {
      bodyFormData.append('deleteLogo', deleteLogo);
    }

    const { data, error } = await axios({
      method: 'put',
      url: `${import.meta.env.VITE_APP_SERVER_URL}/org/entity/${entityId}`,
      data: bodyFormData,
      withCredentials: true,
      'Content-Type': 'multipart/form-data'
    });
    if (error) {
      throw new Error(data);
    }

    commit('SET_CURRENT_ORG', data.data);

    return data;
  },

  async saveReceiptSettings({ commit }, { entityId, receiptForm, signature, deleteSignature = false }) {
    const bodyFormData = new FormData();
    bodyFormData.append('receiptForm', JSON.stringify(receiptForm));
    bodyFormData.append('signature', signature);
    if (deleteSignature) {
      bodyFormData.append('deleteSignature', deleteSignature);
    }
    const { data, error } = await request.fileUpload('put', `/org/entity/receipt/${entityId}`, bodyFormData);
    if (error) {
      throw new Error(data);
    }

    commit('SET_CURRENT_ORG', data.data);
    return data;
  },

  async fetchCampaign(_, campaignId) {
    const { data, error } = await request.get(`/campaign/${campaignId}`);

    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async saveCampaign(_, { campaignId, campaign }) {
    const { data, error } = await request.post(`/campaign/${campaignId}`, { campaign });

    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async fetchOrgStripeData({ state }, orgId = state.currentOrg.id) {
    const { data, error } = await request.get(`/org/${orgId}/stripe-data`);
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async saveCheckoutSettings(_, { settings, campaignId }) {
    const { data, error } = await request.post(`/org/checkout-settings?campaignId=${campaignId}`, { settings });
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  // todo: per checkout_settings
  async getDonationDesignationsInUse(_, orgId = state.currentOrg.id) {
    const { data, error } = await request.get(`/org/${orgId}/designations`);
    if (error) {
      throw new Error(data);
    }

    return data.map((designation) => designation.designation) || [];
  },

  async changeCurrentOrg(_, { id }) {
    const { data, error } = await request.post('/org/current-org', { orgId: id });
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async verifyPaymentDomain(_, { domain, entityId }, orgId = state.currentOrg.id) {
    const { data } = await request.post(`/org/${orgId}/payment-domain`, { entityId, domain });

    return data;
  },

  async chariotIntegrate({ commit }, { entityId, email, ein }) {
    const { data, error } = await request.post(`/org/chariot?entityId=${entityId}`, { email, ein });
    commit('SET_CURRENT_ORG', data);

    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async chariotDisconnect({ commit }, entityId) {
    const { data, error } = await request.delete(`/org/chariot?entityId=${entityId}`);
    if (error) {
      throw new Error(data);
    }

    commit('SET_CURRENT_ORG', data);
    return data;
  },

  async fetchCustomWebhook() {
    const { data, error } = await request.get('/org/webhook');
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async saveCustomWebhook({ commit }, form) {
    const { data, error } = await request.post('/org/webhook', form);
    if (error) {
      throw new Error(data);
    }

    return data;
  }
};
